import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import SEO from '../components/SEO'
import HeroHeader from '../components/HeroHeader'
import GoogleMaps from '../components/GoogleMaps'

const title = 'Anreise | Stadlwirt'

const ArrivalPage = () => {
  const { fluidBackgroundImage } = useStaticQuery(
    graphql`
      query {
        fluidBackgroundImage: file(relativePath: { eq: "anreise.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  )

  return (
    <>
      <SEO title={title} />
      <HeroHeader
        title="Anreise"
        staticBackgroundImage="/uploads/anreise.jpg"
        fluidBackgroundImage={fluidBackgroundImage}
      />

      <section className="bg-gray-900 text-white py-10">
        <div className="container">
          <h2 className="text-2xl">Anreise</h2>
          <hr className="my-2" />
          <p></p>
          <p>
            Für die Anreise zum Stadlwirt stehen Ihnen verschiedene Optionen zur
            Verfügung.
          </p>
          <br />

          <h3 className="text-xl">Mit dem Auto:</h3>
          <ul>
            <li>Kempten - Reutte - Fernpass - Imst - Landeck - Nauders</li>
            <li>Lindau/Feldkirch - Arlberg Tunnel - Landeck - Nauders</li>
            <li>Wien - Linz - Salzburg - Innsbruck - Landeck - Nauders</li>
            <li>
              München - Rosenheim - Kufstein - Kramsach - Innsbruck - Landeck -
              Nauders
            </li>
          </ul>
          <br />

          <h3 className="text-xl">Mit der Bahn:</h3>
          <ul>
            <li>Bahnhof Landeck - Postbusse direkt nach Nauders (ca. 40 km)</li>
          </ul>
          <br />

          <h3 className="text-xl">Mit dem Flugzeug:</h3>
          <ul>
            <li>Flughafen Innsbruck (ca. 120 km)</li>
            <li>Flughafen München (ca. 230 km)</li>
            <li>Flughafen Zürich (ca. 255 km)</li>
          </ul>
        </div>
      </section>

      <section className="py-10">
        <div className="container flex flex-wrap">
          <div className="w-full mb-5 md:order-2 md:w-1/2 md:px-5">
            <h2 className="text-2xl">Kostenfreie Parkplätze verfügbar!</h2>
            <hr className="my-2" />
            <p>
              Unsere hauseigenen Parkplätze stehen Ihnen zur Verfügung. Sie
              befinden sich auf der Nordseite des Restaurants, die genau
              Position ist auf der Karte eingezeichnet.
            </p>
          </div>
          <div className="w-full md:order-1 md:w-1/2">
            <GoogleMaps
              query="Pfarrer-Kathrein-gasse 131, 6543"
              zoomLevel={18}
            />
          </div>
        </div>
      </section>
    </>
  )
}

export default ArrivalPage
